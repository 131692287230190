/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.2
 *
*/

// Google Fonts
@import url("//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&lang=en");


// Variables, Mixins
@import "base/variables";
@import "base/mixins";

// INSPINIA Theme Elements
@import "base/typography";
@import "base/navigation";
@import "base/top_navigation";
@import "base/buttons";
@import "base/badgets_labels";
@import "base/elements";
@import "base/sidebar";
@import "base/base";
@import "base/pages";
@import "base/chat";
@import "base/metismenu";
@import "base/spinners";

// Landing page
@import "base/landing";

// RTL Support
@import "base/rtl";

// For demo only - config box style
@import "base/theme-config";

// INSPINIA Skins
@import "base/skins";

// Media query style
@import "base/media";

// Custom style
// Your custom style to override base style
@import "custom";



