
@media (max-width: 1000px)
  .welcome-message
    display: none

.lightBoxGallery
  text-align: center
  img
    margin: 5px

.tabs-container
  margin-bottom: 25px

.answer
  margin-top: 20px
  padding-top: 20px
  border: 1px dashed #E7EAEC
  cursor: move

.question-meta
  margin-bottom: 20px

.language-box
  display: block
  border: 1px solid #E7EAEC
  padding: 10px
  margin-bottom: 20px

.DTTT_container
  display: none

.fieldname
  width: 21%

.answer-group-container span.label
  margin-right: 10px

.ibox.collapsed .ibox-content
  display: none

.ibox-title h5.question-title, .question-key
  font-size: 1.8rem
.collapse-link
    margin-right: 10px
    font-size: 21px
    line-height: 20px
    margin-right: 10px  
.tab-content .form-group
  float: left
  width: 100%
.CodeMirror-scroll
  height: auto
.sortable-ghost
  background: rgba(0,0,0, 0.1)
  border: 2px dashed #222
#language-chooser
  .btn-info:hover,.btn-info:focus 
    background-color: inherit
    color: #23c6c8
    border-color: #23c6c8 
  .active.btn-info:hover,.active.btn-info:focus 
    background-color: #23c6c8
    color: #fff
    border-color: #23c6c8 
tr[data-href]
  cursor: pointer
.disturl
  font-size: 24px
  word-break: break-all
  .api_base
    font-weight: bold